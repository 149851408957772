<template>
  <div>

    <b-card no-body class="mb-0">

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile_tab_max_width_flex mobile_margin_bottom_1rem">
            <label>Show</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" @input="filterTable" />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6" class="mobile_tab_max_width_flex all_btn_tab ">
            <div class="d-flex align-items-center justify-content-end mobile_tab_display_block">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1 mobile_margin_bottom_1rem"
                placeholder="Search..." @input="filterTable" />

              <b-button v-if="checkPermission($route.name, 'Zip Download') && tab_status == 'completed'" :disabled="selected && selected.length > 0 ? false : true"
                variant="primary" class=" " @click="zipDownload()">
                <span class="text-nowrap">Zip Download</span>
              </b-button>
              <b-button
                v-if="checkPermission($route.name, 'Send Mail') && ($store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'operation_executive')"
                variant="info" class="mobile-margin-bottom ml-1" @click="sendEmail()">
                <span class="text-nowrap">Send Email</span>
              </b-button>
              <!-- <b-button :disabled="selected && selected.length > 0 ? false : true" -->
              <b-button
                @click="approveAllModal"
                variant="success"
                class="mobile-margin-bottom ml-1"
                v-if="checkPermission($route.name, 'Approve All') && tab_status == 'pending'"
              >
                <!-- v-if="tab_status == 'pending' && $store.getters.currentUser.role == 'admin'" -->
                <span class="text-nowrap">Approve All</span>
              </b-button>

              <b-button variant="warning" class="mobile-margin-bottom ml-1" :to="{ name: 'add-inventory-order' }"
                v-if="checkPermission($route.name, 'Add') && (tab_status != 'completed' && checkOrderCount)">
                <span class="text-nowrap">Add</span>
              </b-button>


            </div>
          </b-col>

        </b-row>
      </div>

      <b-table responsive show-empty ref="refUserListTable"
        class="overflow_y b-table-sticky-header position-relative midTableRpt common_big_tbl" primary-key="id"
        empty-text="No matching records found" :fields="true ? tableColumns2 : tableColumns" :sort-by.sync="sortBy"
        :items="dataList" :sort-desc.sync="isSortDirDesc"
        :class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'">

        <template #cell(info)="items">
          <feather-icon :icon="items.detailsShowing ? 'EyeIcon' : 'EyeOffIcon'" @click="items.toggleDetails" />
        </template>

        <template #head(checkbox)="items">
          <b-form-checkbox class="custom-control-warning" v-model="selectAll" @change="getAll" />
        </template>

        <template #cell(checkbox)="items">
          <!-- v-if="checkPermission('custom-forms-dashboard', 'Delete') && ((items.item.level_user_status != null) && (items.item.level_user_status.role == $store.getters.currentUser.role))" -->
          <b-form-checkbox v-model="selected" :value="items.item._id" class="custom-control-warning my-1" />
        </template>

        <template #cell(unique_id)="items">
          <span class="" @click="items.toggleDetails">{{ items.item.unique_id | capitalize }}</span>
        </template>

        <template #cell(site_name)="items">
          <span class="wordBreak" @click="items.toggleDetails">{{ items.item.site.site_name | capitalize }}</span>
        </template>

        <template #cell(month)="items">
          <span class="wordBreak" @click="items.toggleDetails">{{ items.item.month | capitalize }}</span>
        </template>

        <!-- <template #cell(order_type)="items">
          <span class="wordBreak" @click="items.toggleDetails">{{ items.item.order_type | capitalize }}</span>
        </template> -->

        <template #cell(supplier)="items">
          <!-- <span  v-if="getSupplierCategoriesText(items.item.orderData).length > 50" v-b-tooltip.hover.v-primary.top
            :title="getSupplierCategoriesText(items.item.orderData)" class="wordBreak">{{ getSupplierCategoriesText(items.item.orderData) |
              truncate }}</span>
          <span  v-else class="wordBreak">{{ getSupplierCategoriesText(items.item.orderData) }}</span> -->
          <span class="wordBreak">{{ getSupplierCategoriesText(items.item.orderData) }}</span>
        </template>

        <!-- <template #cell(order_by)="items">
          <span class="wordBreak">{{ items.item.order_by | capitalize }}</span>
        </template> -->

        <!-- <template #cell(purchase_order_ids)="items">
          <span class="wordBreak">{{ items.item.purchase_order_ids | capitalize }}</span>
        </template> -->

        <!-- <template #cell(purchase_order_generated)="items">
          <span class="wordBreak">{{ items.item.purchase_order_generated | capitalize }}</span>
        </template> -->

        <template #cell(date)="items">
          <span class="wordBreak" @click="items.toggleDetails">{{ sitedateTime(items.item.date, date_format, '')
            }}</span>
        </template>

        <!-- <template #cell(reject_comment)="items">
          <span v-if="items.item.reject_comment && items.item.reject_comment.length > 50" v-b-tooltip.hover.v-primary.top
            :title="items.item.reject_comment" class="wordBreak">{{ items.item.reject_comment | capitalize |
              truncate }}</span>
          <span v-else class="wordBreak">{{ items.item.reject_comment | capitalize }}</span>
        </template> -->

        <!-- <template #cell(level_one_user_remarks)="items">
          <span v-b-tooltip.hover.v-primary.top
            :title="items.item.level_one_user.role + ' - ' + items.item.level_one_user_remarks | removeUnderCapitalize"
            class="wordBreak"
            v-if="(items.item.level_one_user_remarks != null && items.item.level_one_user_remarks != '') && items.item.level_one_user_remarks.length > 50">{{
              items.item.level_one_user.role
              | removeUnderCapitalize }} - {{ items.item.level_one_user_remarks | capitalize | truncate }}</span>
          <span class="wordBreak"
            v-else-if="(items.item.level_one_user_remarks != null && items.item.level_one_user_remarks != '') && items.item.level_one_user_remarks.length <= 50">{{
              items.item.level_one_user.role
              | removeUnderCapitalize }} - {{ items.item.level_one_user_remarks | capitalize | truncate }}</span>
          <span class="wordBreak" v-else>{{ 'N/A' }}</span>
        </template> -->

        <!-- <template #cell(level_two_user_remarks)="items">
          <span v-b-tooltip.hover.v-primary.top
            :title="items.item.level_two_user.role + ' - ' + items.item.level_two_user_remarks | removeUnderCapitalize"
            class="wordBreak"
            v-if="(items.item.level_two_user_remarks != null && items.item.level_two_user_remarks != '') && items.item.level_two_user_remarks.length > 50">{{
              items.item.level_two_user.role
              | removeUnderCapitalize }} - {{ items.item.level_two_user_remarks | capitalize | truncate }}</span>
          <span class="wordBreak"
            v-else-if="(items.item.level_two_user_remarks != null && items.item.level_two_user_remarks != '') && items.item.level_two_user_remarks.length <= 50">{{
              items.item.level_two_user.role
              | removeUnderCapitalize }} - {{ items.item.level_two_user_remarks | capitalize | truncate }}</span>
          <span class="wordBreak" v-else>{{ 'N/A' }}</span>
        </template> -->

        <!-- <template #cell(level_three_user_remarks)="items">
          <span v-b-tooltip.hover.v-primary.top
            :title="items.item.level_three_user.role + ' - ' + items.item.level_three_user_remarks | removeUnderCapitalize"
            class="wordBreak"
            v-if="(items.item.level_three_user_remarks != null && items.item.level_three_user_remarks != '') && items.item.level_three_user_remarks.length > 50">{{
              items.item.level_three_user.role
              | removeUnderCapitalize }} - {{ items.item.level_three_user_remarks | capitalize | truncate }}</span>
          <span class="wordBreak"
            v-else-if="(items.item.level_three_user_remarks != null && items.item.level_three_user_remarks != '') && items.item.level_three_user_remarks.length <= 50">{{
              items.item.level_three_user.role
              | removeUnderCapitalize }} - {{ items.item.level_three_user_remarks | capitalize | truncate }}</span>
          <span class="wordBreak" v-else>{{ 'N/A' }}</span>
        </template> -->

        <template #cell(status)="items">

          <div>

            <b-badge pill variant="danger" class="text-capitalize" v-if="(items.item.reject_to != null)"
              @click="items.toggleDetails">
              <!-- {{ 'L1 Pending'}} -->
              <span>
                {{ 'Rejected - ' + items.item.reject_by | removeUnderCapitalize }}
              </span>

            </b-badge>

            <b-badge pill variant="warning" class="text-capitalize" @click="items.toggleDetails"
              v-else-if="items.item.reject_to == null && items.item.level_user_status!== null">
              <span v-if="items.item.level_user_status.role == $store.getters.currentUser.role">
                {{ 'Pending' | removeUnderCapitalize }}
              </span>
              <span v-else>
                {{ 'Pending - ' + items.item.level_user_status.role | removeUnderCapitalize }}
              </span>
            </b-badge>

            <!-- <b-badge pill variant="warning" class="text-capitalize" @click="items.toggleDetails"
              v-else-if="(items.item.level_one_user_signature == null && items.item.level_user_status != null)">
              {{ 'L1 Pending'}}
              <span v-if="items.item.level_user_status.role == $store.getters.currentUser.role">
                {{ 'Pending' | removeUnderCapitalize }}
              </span>
              <span v-else>
                {{ 'Pending - ' + items.item.level_user_status.role | removeUnderCapitalize }}
              </span>
            </b-badge> -->


            <!-- <b-badge pill variant="warning" class="text-capitalize" @click="items.toggleDetails"
              v-else-if="(items.item.level_user_status != null) && (items.item.level_one_user_signature != null) && (items.item.level_two_user_signature == null)">
              {{ 'L2 Pending'}}


              <span v-if="items.item.level_user_status.role == $store.getters.currentUser.role">
                {{ 'Pending' | removeUnderCapitalize }}
              </span>
              <span v-else>
                {{ 'Pending - ' + items.item.level_two_user_role | removeUnderCapitalize }}
              </span>

            </b-badge>

            <b-badge pill variant="warning" class="text-capitalize" @click="items.toggleDetails"
              v-else-if="(items.item.level_user_status != null) && (items.item.level_two_user_signature != null)">
              {{ 'L2 Pending'}}

              <span v-if="items.item.level_user_status.role == $store.getters.currentUser.role">
                {{ 'Pending' | removeUnderCapitalize }}
              </span>
              <span v-else>
                {{ 'Pending - ' + items.item.level_three_user_role | removeUnderCapitalize }}
              </span>

            </b-badge> -->

            <b-badge pill variant="secondary" class="text-capitalize" v-else-if="items.item.status == 'draft'"
              @click="items.toggleDetails">
              {{ 'Drafted' }}
            </b-badge>

            <b-badge pill variant="success" class="text-capitalize" v-else @click="items.toggleDetails">
              {{ 'Completed' }}
            </b-badge>

          </div>


        </template>

        <template #cell(actions)="items">
           <b-row>
            <div class="p-0 pb-1 mobile_col col-4" v-if="($store.getters.currentUser.role == 'administrator' || $store.getters.currentUser.role == 'admin') && tab_status == 'completed'">
              <b-link
                v-if="checkPermission($route.name, 'Edit') && (($store.getters.currentUser.role == 'administrator' || $store.getters.currentUser.role == 'admin') && tab_status == 'completed')"
                v-b-tooltip.hover.v-warning title="Update" variant="outline-warning"
                :to="{ name: 'update-inventory-order', params: { id: items.item._id } }">
                <feather-icon icon="EditIcon" class="mr-1 mediumSize" />
              </b-link>
            </div>
            <div class="p-0 pb-1 mobile_col col-4"
              v-if="checkPermission($route.name, 'Delete') && ((items.item.level_user_status != null) && ((items.item.level_user_status.role == $store.getters.currentUser.role)))">
              <b-link
                v-if="((items.item.level_user_status != null) && ((items.item.level_user_status.role == $store.getters.currentUser.role)))"
                v-b-tooltip.hover.v-warning title="Delete" variant="outline-warning"
                @click="deleteItem(items.item._id)">
                <feather-icon icon="Trash2Icon" class="mr-1 mediumSize" />

              </b-link>
            </div>
            <b-link v-else-if="checkPermission($route.name, 'Delete') && (items.item.status == 'draft')" v-b-tooltip.hover.v-warning title="Delete"
              variant="outline-warning" @click="deleteItem(items.item._id)">
              <feather-icon icon="Trash2Icon" class="mr-1 mediumSize" />
            </b-link>

            <div class="p-0 pb-1 mobile_col col-4" v-if="checkPermission($route.name, 'Download') && items.item.status != 'draft'">
              <b-link v-b-tooltip.hover.v-warning title="Download" variant="outline-warning"
                @click="download(items.item.pdf)">
                <feather-icon icon="DownloadIcon" class="mr-1 mediumSize" />
              </b-link>
            </div>
            <div class="p-0 pb-1 mobile_col col-4" v-if="checkPermission($route.name, 'Generate Purchase Order') && (items.item.purchase_order_generated == 'yes' && ($store.getters.currentUser.role == 'administrator' || $store.getters.currentUser.role == 'admin'))">
              <b-link v-b-tooltip.hover.v-warning :title="items.item.purchase_order_generated == 'yes' ? 'Regenerate Purchase Order' : 'Generate Purchase Order'" variant="outline-warning"
                v-if="items.item.purchase_order_generated == 'yes' && ($store.getters.currentUser.role == 'administrator' || $store.getters.currentUser.role == 'admin')"
                @click="generatePurchaseOrder(items.item._id)">
                <feather-icon :icon="items.item.purchase_order_generated == 'yes' ? 'RefreshCwIcon' : 'FileTextIcon'"
                  class="mr-1 mediumSize" />
              </b-link>
            </div>
            <div class="p-0 pb-1 mobile_col col-4"
              v-if="(((items.item.level_user_status != null) && ((items.item.level_user_status.role == $store.getters.currentUser.role))) || ((items.item.reject_to == $store.getters.currentUser.role)))">
              <b-link
                v-if="checkPermission($route.name, 'Edit') && (((items.item.level_user_status != null) && ((items.item.level_user_status.role == $store.getters.currentUser.role))) || ((items.item.reject_to == $store.getters.currentUser.role)))"
                v-b-tooltip.hover.v-warning title="Edit" variant="outline-warning"
                :to="{ name: 'add-inventory-order', params: { id: items.item._id } }">
                <feather-icon icon="EditIcon" class="mr-1 mediumSize" />
              </b-link>
            </div>
            <b-link v-else-if="checkPermission($route.name, 'Edit') && (items.item.status == 'draft' && $store.getters.currentUser._id == items.item.created_by)"
              :to="{ name: 'add-inventory-order', params: { id: items.item._id } }">
              <feather-icon icon="EditIcon" class="mr-1 mediumSize" />
            </b-link>
            <div class="p-0 pb-1 mobile_col col-4"
              v-if="checkPermission($route.name, 'Sign') && (((items.item.level_user_status != null) && ((items.item.level_user_status.role == $store.getters.currentUser.role)) && items.item.reject_comment == null))">
              <b-link
                v-if="((items.item.level_user_status != null) && ((items.item.level_user_status.role == $store.getters.currentUser.role)) && items.item.reject_comment == null)"
                v-b-tooltip.hover.v-warning title="Sign" variant="outline-warning"
                @click="openSignature(items.item._id, items.item.date, items.item.special_requisition)">
                <feather-icon icon="CheckCircleIcon" class="mr-1 mediumSize" />
              </b-link>
            </div>
            <div class="p-0 pb-1 mobile_col col-4"
              v-if="checkPermission($route.name, 'Reject') && (((items.item.level_user_status != null) && ((items.item.level_user_status.role == $store.getters.currentUser.role)) && allowReject(items.item)))">
              <b-link
                v-if="((items.item.level_user_status != null) && ((items.item.level_user_status.role == $store.getters.currentUser.role)) && allowReject(items.item))"
                v-b-tooltip.hover.v-warning title="Reject" variant="outline-warning" @click="openRejectBox(items.item)">
                <feather-icon icon="SlashIcon" class="mr-1 mediumSize" />
              </b-link>
            </div>
          </b-row>

        </template>

        <template #row-details="items">
          <div class="p-1 px-2 fbDetail mb-1">
            <b-row>

              <b-col cols="12" xl="12">
                <table class="mt-2 mt-xl-0 w-100">
                  <tr class="mb-1">
                    <!-- making the remarks dynamic -->
                    <!-- <th class="pb-50" style="width: 10%">
											<div class="d-flex align-items-center">
									
											<div class="ml-1">
												<h6 class="mb-0 mediumFonts">
												  LEVEL 1 REMARKS
												</h6>
											</div>
											</div>
										</th>
										<td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                           <span v-b-tooltip.hover.v-primary.top
                            :title="items.item.level_one_user.role + ' - ' + items.item.level_one_user_remarks | removeUnderCapitalize"
                            class="wordBreak"
                            v-if="(items.item.level_one_user_remarks != null && items.item.level_one_user_remarks != '') && items.item.level_one_user_remarks.length > 50">{{
                              items.item.level_one_user.role
                              | removeUnderCapitalize }} - {{ items.item.level_one_user_remarks | capitalize | truncate }}</span>
                          <span class="wordBreak"
                            v-else-if="(items.item.level_one_user_remarks != null && items.item.level_one_user_remarks != '') && items.item.level_one_user_remarks.length <= 50">{{
                              items.item.level_one_user.role
                              | removeUnderCapitalize }} - {{ items.item.level_one_user_remarks | capitalize | truncate }}</span>
                          <span class="wordBreak" v-else>{{ 'N/A' }}</span>
										</td>

										<th class="pb-50" style="width: 5%">
											<div class="d-flex align-items-center">
									
											<div class="ml-1">
												<h6 class="mb-0 mediumFonts">
												  LEVEL 2 REMARKS
												</h6>
											
											</div>
											</div>
										</th>
										<td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                        <span v-b-tooltip.hover.v-primary.top
                          :title="items.item.level_two_user.role + ' - ' + items.item.level_two_user_remarks | removeUnderCapitalize"
                          class="wordBreak"
                          v-if="(items.item.level_two_user_remarks != null && items.item.level_two_user_remarks != '') && items.item.level_two_user_remarks.length > 50">{{
                            items.item.level_two_user.role
                            | removeUnderCapitalize }} - {{ items.item.level_two_user_remarks | capitalize | truncate }}</span>
                        <span class="wordBreak"
                          v-else-if="(items.item.level_two_user_remarks != null && items.item.level_two_user_remarks != '') && items.item.level_two_user_remarks.length <= 50">{{
                            items.item.level_two_user.role
                            | removeUnderCapitalize }} - {{ items.item.level_two_user_remarks | capitalize | truncate }}</span>
                        <span class="wordBreak" v-else>{{ 'N/A' }}</span>
										</td>

                    	<th class="pb-50" style="width: 5%">
											<div class="d-flex align-items-center">
									
											<div class="ml-1">
												<h6 class="mb-0 mediumFonts">
												 LEVEL 3 REMARKS
												</h6>
											
											</div>
											</div>
										</th>
										<td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                        <span v-b-tooltip.hover.v-primary.top
                        :title="items.item.level_three_user.role + ' - ' + items.item.level_three_user_remarks | removeUnderCapitalize"
                        class="wordBreak"
                        v-if="(items.item.level_three_user_remarks != null && items.item.level_three_user_remarks != '') && items.item.level_three_user_remarks.length > 50">{{
                          items.item.level_three_user.role
                          | removeUnderCapitalize }} - {{ items.item.level_three_user_remarks | capitalize | truncate }}</span>
                      <span class="wordBreak"
                        v-else-if="(items.item.level_three_user_remarks != null && items.item.level_three_user_remarks != '') && items.item.level_three_user_remarks.length <= 50">{{
                          items.item.level_three_user.role
                          | removeUnderCapitalize }} - {{ items.item.level_three_user_remarks | capitalize | truncate }}</span>
                      <span class="wordBreak" v-else>{{ 'N/A' }}</span>
										</td> -->
                    <!-- <td v-for="(validation_record, index) in items.item.validation_record" style="width: 20% !important;">
                      <span style="width: 35%" class="pb-50">
											<div class="d-flex align-items-center">
									
											<div class="ml-1">
												<h6 class="mb-0 mediumFonts">
												  LEVEL {{ index + 1 }} REMARKS
												</h6>
											</div>
											</div>
										</span>
										<span class="pb-50 mediumFonts" style="width: 35%;padding-top: 8px;">
                           <span v-b-tooltip.hover.v-primary.top
                            :title="validation_record.role + ' - ' + validation_record.remark | removeUnderCapitalize"
                            class="wordBreak"
                            v-if="(validation_record.remark != null && validation_record.remark != '') && validation_record.remark.length > 50">{{
                              validation_record.role
                              | removeUnderCapitalize }} - {{ validation_record.remark | capitalize | truncate }}</span>
                          <span class="wordBreak"
                            v-else-if="(validation_record.remark != null && validation_record.remark != '') && validation_record.remark.length <= 50">{{
                              validation_record.role
                              | removeUnderCapitalize }} - {{ validation_record.remark | capitalize | truncate }}</span>
                          <span class="wordBreak" v-else>N/A</span>
										</span>
                  </td> -->
                  </tr>
                  <tr class="mb-1">
                    <th class="pb-50" style="width: 10%">
                      <div class="d-flex align-items-center">

                        <div class="ml-1">
                          <h6 class="mb-0 mediumFonts">
                            REJECT REASON
                          </h6>

                        </div>
                      </div>
                    </th>
                    <td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                      <span v-if="items.item.reject_comment && items.item.reject_comment.length > 50"
                        v-b-tooltip.hover.v-primary.top :title="items.item.reject_comment" class="wordBreak">{{
                          items.item.reject_comment | capitalize |
                          truncate }}</span>
                      <span v-else class="wordBreak">{{ items.item.reject_comment | capitalize }}</span>
                    </td>
                    <th class="pb-50" style="width: 10%">
                      <div class="d-flex align-items-center">

                        <div class="ml-1">
                          <h6 class="mb-0 mediumFonts">
                            PURCHASE ORDER ID
                          </h6>

                        </div>
                      </div>
                    </th>
                    <td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                      <span class="wordBreak">{{ items.item.purchase_order_ids | capitalize }}</span>
                    </td>
                    <th class="pb-50" style="width: 10%">
                      <div class="d-flex align-items-center">

                        <div class="ml-1">
                          <h6 class="mb-0 mediumFonts">
                            PURCHASE ORDER GENERATED
                          </h6>

                        </div>
                      </div>
                    </th>
                    <td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                      <span class="wordBreak">{{ items.item.purchase_order_generated | capitalize }}</span>
                    </td>
                  </tr>
                  <tr class="mb-1">
                    <!-- <th class="pb-50" style="width: 10%">
                        <div class="d-flex align-items-center">
                    
                        <div class="ml-1">
                          <h6 class="mb-0 mediumFonts">
                           SUPPLIER CATEGORIES
                          </h6>
                      
                        </div>
                        </div>
                      </th>
                      <td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                            <span @click="items.toggleDetails" v-if="getSupplierCategoriesText(items.item.orderData).length > 50" v-b-tooltip.hover.v-primary.top
                          :title="getSupplierCategoriesText(items.item.orderData)" class="wordBreak">{{ getSupplierCategoriesText(items.item.orderData) |
                            truncate }}</span>
                        <span  @click="items.toggleDetails" v-else class="wordBreak">{{ getSupplierCategoriesText(items.item.orderData) }}</span>
                      </td> -->
                    <th class="pb-50" style="width: 10%">
                      <div class="d-flex align-items-center">

                        <div class="ml-1">
                          <h6 class="mb-0 mediumFonts">
                            ORDER BY
                          </h6>

                        </div>
                      </div>
                    </th>
                    <td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                      <span class="wordBreak">{{ items.item.order_by | capitalize }}</span>
                    </td>

                    <th class="pb-50" style="width: 10%">
                      <div class="d-flex align-items-center">

                        <div class="ml-1">
                          <h6 class="mb-0 mediumFonts">
                            ORDER TYPE
                          </h6>

                        </div>
                      </div>
                    </th>
                    <td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                      {{ items.item.order_type | capitalize }}
                    </td>
                  </tr>
                  <tr class="mb-1 mt-1">
                    <td colspan="6">
                      <div style="display: flex;flex-direction:row;flex-wrap: wrap;margin-top: 10px;gap: 5%;">
                        <div v-for="(validation_record, index) in items.item.validation_record"
                          style="width: 30%;display: flex;flex-direction: row;flex-wrap: nowrap;justify-content: space-evenly;">
                          <div style="width:40%;padding-top: 8px;" class="pb-50">
                            <div class="d-flex align-items-center">

                              <div class="ml-1">
                                <h6 class="mb-0 mediumFonts">
                                  LEVEL {{ index + 1 }} REMARKS
                                </h6>
                              </div>
                            </div>
                          </div>
                          <div class="pb-50 mediumFonts" style="width: 60%;padding-top: 8px;padding-left: 17%;">
                            <span v-b-tooltip.hover.v-primary.top
                              :title="validation_record.role + ' - ' + validation_record.remark | removeUnderCapitalize"
                              class="wordBreak"
                              v-if="(validation_record.remark != null && validation_record.remark != '') && validation_record.remark.length > 50">{{
                                validation_record.role
                                | removeUnderCapitalize }} - {{ validation_record.remark | capitalize | truncate }}</span>
                            <span class="wordBreak"
                              v-else-if="(validation_record.remark != null && validation_record.remark != '') && validation_record.remark.length <= 50">{{
                                validation_record.role
                                | removeUnderCapitalize }} - {{ validation_record.remark | capitalize | truncate }}</span>
                            <span class="wordBreak" v-else>N/A</span>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr class="mb-1">
                    <th class="pb-50" style="width: 16%">
                      <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-secondary"
                        @click="items.toggleDetails" size="sm" class="ml-1">
                        Hide Detail
                      </b-button>
                    </th>
                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">

                    </td>
                    <th class="pb-50" style="width: 16%">
                    </th>
                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">

                    </td>
                    <th class="pb-50" style="width: 16%">
                    </th>
                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">

                    </td>
                  </tr>
                </table>
              </b-col>

            </b-row>
          </div>

        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
          </b-col>

          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @input="filterTable">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>

        </b-row>
      </div>

    </b-card>
    <b-modal id="signatureModel" ref="signatureModel" centered title="Signature" no-close-on-backdrop hide-footer
      @hide="hideSignature" @show="hideSignature">

      <div v-if="show_default == true">
        <b-row>

          <b-col md="11"
            style="background: #ffffff; height:300px; vertical-align: middle;margin-left: 4%;margin-right:2%">
            <center>
              <img :src="tempSign.image" width="240px" height="200px"
                style="background: #ffffff; border-radius: 4px;margin-top: 11%">
            </center>

          </b-col>
        </b-row>

        <b-row class="mt-2">

          <b-col md="12">
            <date-picker v-model="approve_signature_time" format="DD MMM YYYY HH:mm" type="datetime"
              placeholder="Select" valueType="format" :clearable=false :confirm=true lang="en"
              :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
              :disabled="$store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'admin' ? false : true"
              :disabled-date="disabledDate"></date-picker>


          </b-col>
        </b-row>
        <b-row class="mt-2">

          <b-col md="12">
            <b-form-group label="Remarks" class="">
              <b-form-textarea placeholder="" rows="3" v-model="approve_remark" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">

          <b-col md="6">

            <b-button variant="secondary" class="mt-0 w-100" @click="show_default = false">
              <span class="text-nowrap">Add New Signature</span>
            </b-button>
          </b-col>

          <b-col md="6">
            <b-button v-if="id == ''" variant="warning" class="mt-0 w-100" @click="saveDefaultAll();">
              <span class="text-nowrap">Submit</span>
            </b-button>
            <b-button v-else variant="warning" class="mt-0 float-right w-100" @click="saveDefault()">
              <span class="text-nowrap">Submit</span>
            </b-button>
          </b-col>
        </b-row>
      </div>

      <div v-else>
        <b-row>
          <b-col md="12">
            <VueSignaturePad id="signature" width="100%" height="300px" ref="signaturePad"
              :options="{ onBegin: () => { $refs.signaturePad.resizeCanvas() }, penColor: '#000000', minWidth: 3, maxWidth: 3 }" />

          </b-col>
        </b-row>
        <b-row class="mt-2">

          <b-col md="12">
            <date-picker v-model="approve_signature_time" format="DD MMM YYYY HH:mm" type="datetime"
              placeholder="Select" valueType="format" :clearable=false :confirm=true lang="en"
              :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
              :disabled="$store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'admin' ? false : true"
              :disabled-date="disabledDate"></date-picker>


          </b-col>
        </b-row>
        <b-row class="mt-2">

          <b-col md="12">
            <b-form-group label="Remarks" class="">
              <b-form-textarea placeholder="" rows="3" v-model="approve_remark" />
            </b-form-group>
          </b-col>
        </b-row>



        <b-row class="mt-2">

          <b-col md="4" v-if="having_default == true">

            <b-button variant="secondary" class="mt-0 w-100" @click="show_default = true">
              <span class="text-nowrap">Default</span>
            </b-button>
          </b-col>


          <b-col :md="having_default == true ? '4' : '6'">

            <b-button variant="danger" class="mt-0 w-100" @click="clearSignature">
              <span class="text-nowrap">Clear</span>
            </b-button>
          </b-col>

          <b-col md="4" :md="having_default == true ? '4' : '6'">
            <b-button v-if="id == ''" variant="warning" class="mt-0 w-100" @click="saveSignatureAll">
              <span class="text-nowrap">Submit</span>
            </b-button>
            <b-button v-else variant="warning" class="mt-0 w-100" @click="saveSignature">
              <span class="text-nowrap">Submit</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <b-modal id="rejectbox" ref="rejectbox" cancel-variant="outline-secondary" ok-variant="outline-warning"
      ok-title="Submit" cancel-title="Close" centered title="Reject Report" no-close-on-backdrop @ok="markReject">

      <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
        <div class="alert-body">
          {{ error_message }}
        </div>
      </b-alert>
      <b-form>
        <b-row>
          <b-col md="12">
            <b-form-group label="Reason" class="required">
              <b-form-textarea placeholder="3 to 500 characters" rows="3" v-model="comment" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>

    </b-modal>

    <b-modal id="approveAll" ref="approveAll" cancel-variant="outline-secondary" size="lg" 
      ok-variant="outline-warning" ok-title="Submit" cancel-title="Close" scrollable 
      title="Approve Order" no-close-on-backdrop @ok="openSignature('', '', '')"
      :ok-disabled="selectedApprove.length === 0"
    >
      <b-table responsive show-empty ref="refUserListTable2" primary-key="id" :sort-by.sync="sortBy"
        class="overflow_y b-table-sticky-header position-relative" 
        empty-text="No matching records found" :fields="tableColumnsApprove"  
        :items="dataListApprove" :sort-desc.sync="isSortDirDesc"
        :class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'">

        <template #head(checkbox)="items">
          <b-form-checkbox class="custom-control-warning" v-model="selectAllApprove" @change="getAllApprove" />
        </template>

        <template #cell(checkbox)="items">
          <b-form-checkbox v-model="selectedApprove" :value="items.item._id" class="custom-control-warning my-1" />
        </template>

        <template #cell(unique_id)="items">
          <span class="wordBreak" @click="items.toggleDetails">{{ items.item.unique_id | capitalize }}</span>
        </template>

        <template #cell(site_name)="items">
          <span class="wordBreak" @click="items.toggleDetails">{{ items.item.site.site_name | capitalize }}</span>
        </template>

        <template #cell(month)="items">
          <span class="wordBreak" @click="items.toggleDetails">{{ items.item.month | capitalize }}</span>
        </template>

        <template #cell(supplier)="items">
          <span class="wordBreak">{{ getSupplierCategoriesText(items.item.orderData) }}</span>
        </template>

        <template #cell(date)="items">
          <span class="wordBreak" @click="items.toggleDetails">{{ sitedateTime(items.item.date, date_format, '') }}</span>
        </template>

        <template #cell(status)="items">
          <div>
            <b-badge pill variant="danger" class="text-capitalize" v-if="(items.item.reject_to != null)"
              @click="items.toggleDetails">
              <span>
                {{ 'Rejected - ' + items.item.reject_by | removeUnderCapitalize }}
              </span>

            </b-badge>

            <b-badge pill variant="warning" class="text-capitalize" @click="items.toggleDetails"
              v-else-if="items.item.reject_to == null && items.item.level_user_status!== null">
              <span v-if="items.item.level_user_status.role == $store.getters.currentUser.role">
                {{ 'Pending' | removeUnderCapitalize }}
              </span>
              <span v-else>
                {{ 'Pending - ' + items.item.level_user_status.role | removeUnderCapitalize }}
              </span>
            </b-badge>

            <b-badge pill variant="secondary" class="text-capitalize" v-else-if="items.item.status == 'draft'"
              @click="items.toggleDetails">
              {{ 'Drafted' }}
            </b-badge>

            <b-badge pill variant="success" class="text-capitalize" v-else @click="items.toggleDetails">
              {{ 'Completed' }}
            </b-badge>

          </div>


        </template>

      </b-table>
    </b-modal>
  </div>
</template>

<script>
import {
  BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip, BFormDatepicker, BFormSelect, BFormSelectOption, BBreadcrumb,
  BFormTextarea, BForm, BFormGroup, BAlert, BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import moment from "moment";
import Bus from "../../../event-bus";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  props: ["siteData", "start", "end", "tab_status"],
  components: {
    BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, vSelect, BFormDatepicker, BFormSelect, BFormSelectOption, BBreadcrumb,
    DatePicker, BFormTextarea, BForm, BFormGroup, BAlert, BFormCheckbox
  },
  watch: {
    siteData: function (newVal, oldVal) { // watch it
      this.filterTable();
      this.updateDateFormat();
    },
    start: function (newVal, oldVal) { // watch it
      this.filterTable();
    },
    end: function (newVal, oldVal) { // watch it
      this.filterTable();
    },
    tab_status: function (newVal, oldVal) { // watch it
      this.filterTable();
    },
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data() {
    return {
      checkOrderCount: false,
      date_format: 'DD MMM YYYY',
      time_format: 'HH:mm',
      webUrl: process.env.VUE_APP_SERVER_URL,
      error_message: null,
      showDismissibleAlert: false,
      tableColumns: [
        { key: 'unique_id', label: 'ID', sortable: true, thStyle: { width: '8%' } },
        { key: 'site_name', label: 'Project Site Name', sortable: false, thStyle: { width: '10%' } },

        { key: 'month', label: 'Month', sortable: false, thStyle: { width: '8%' } },
        { key: 'order_type', label: 'Order Type', sortable: false, thStyle: { width: '10%' } },

        { key: 'date', label: 'Order Date', sortable: true, thStyle: { width: '8%' } },
        { key: 'order_by', label: 'Order By', sortable: true, thStyle: { width: '8%' } },

        { key: 'supplier', label: 'Supplier Categories', sortable: false, thStyle: { width: '8%' } },


        { key: 'level_one_user_remarks', label: 'Level 1 Remarks', sortable: true, thStyle: { width: '8%' } },
        { key: 'level_two_user_remarks', label: 'Level 2 Remarks', sortable: true, thStyle: { width: '8%' } },
        { key: 'level_three_user_remarks', label: 'Level 3 Remarks', sortable: true, thStyle: { width: '8%' } },

        { key: 'reject_comment', label: 'Reject Reason', sortable: true, thStyle: { width: '8%' } },
        { key: 'status', label: 'Status', sortable: false, thClass: 'alignCenter', tdClass: 'alignCenter', thStyle: { width: '8%' } },
        { key: 'actions', label: 'Action', sortable: false, thStyle: { width: '10%' } },
      ],
      tableColumns2: [
        { key: 'checkbox', label: '', sortable: false, thStyle: { width: '1%' } },
        { key: 'info', label: 'INFO', sortable: false, thStyle: { width: '1%' } },
        { key: 'unique_id', label: 'ID', sortable: true, thStyle: { width: '24%' } },
        { key: 'site_name', label: 'Project Site Name', sortable: false, thStyle: { width: '14%' } },

        { key: 'month', label: 'Month', sortable: false, thStyle: { width: '10%' } },
        //{ key: 'order_type', label: 'Order Type', sortable: false, thStyle: { width: '10%' } },
        { key: 'date', label: 'Order Date', sortable: true, thStyle: { width: '15%' } },
        // { key: 'unique_id', label: 'ID', sortable: true, thStyle: { width: '28%' } },
        // { key: 'site_name', label: 'Project Site Name', sortable: false, thStyle: { width: '17%' } },
        // { key: 'month', label: 'Month', sortable: false, thStyle: { width: '8%' } },
        // { key: 'date', label: 'Order Date', sortable: true, thStyle: { width: '12%' } },
        // { key: 'order_by', label: 'Order By', sortable: true, thStyle: { width: '10%' } },

        // { key: 'supplier', label: 'Supplier Categories', sortable: false, thStyle: { width: '7%' } },

        // { key: 'purchase_order_ids', label: 'Purchase Order ID', sortable: false, thStyle: { width: '8%' } },
        // { key: 'purchase_order_generated', label: 'Purchase Order Generated', sortable: false, thStyle: { width: '4%' } },

        // { key: 'level_one_user_remarks', label: 'Level 1 Remarks', sortable: true, thStyle: { width: '7%' } },
        // { key: 'level_two_user_remarks', label: 'Level 2 Remarks', sortable: true, thStyle: { width: '7%' } },
        // { key: 'level_three_user_remarks', label: 'Level 3 Remarks', sortable: true, thStyle: { width: '7%' } },

        // { key: 'reject_comment', label: 'Reject Reason', sortable: true, thStyle: { width: '6%' } },
        { key: 'supplier', label: 'Supplier', sortable: false, thClass: 'alignCenter', tdClass: 'alignCenter', thStyle: { width: '12%' } },
        { key: 'status', label: 'Status', sortable: false, thClass: 'alignCenter', tdClass: 'alignCenter', thStyle: { width: '7%' } },
        { key: 'actions', label: 'Action', sortable: false, thStyle: { width: '16%' } },
      ],



      // b-table and pagination options
      items: [],
      isSortDirDesc: true,
      perPageOptions: [10, 20, 50, 100],
      perPage: 10,
      sortBy: 'id',
      totalRecords: 0,
      currentPage: 1,
      searchQuery: '',
      from: null,
      to: null,
      // calendar vars 

      supervisorSign: {
        image: null,
        name: '',
        type: 'supervisor',
        default: 'no'
      },
      id: null,
      show_default: false,
      having_default: false,
      approve_signature_time: null,
      approve_remark: '',
      checked_date: null,
      comment: null,
      // select all vars
      selectAll: false,
      selected: [],

      // Approve All vars 
      dataListApprove: [],
      selectAllApprove: false,
      selectedApprove: [],
      tableColumnsApprove: [
        { key: 'checkbox', label: '', sortable: false, thStyle: { width: '1%' } },
        { key: 'unique_id', label: 'ID', sortable: true, thStyle: { width: '24%' } },
        { key: 'site_name', label: 'Project Site Name', sortable: false, thStyle: { width: '17%' } },
        { key: 'month', label: 'Month', sortable: false, thStyle: { width: '12%' } },
        { key: 'date', label: 'Order Date', sortable: true, thStyle: { width: '12%' } },
        { key: 'supplier', label: 'Supplier', sortable: false, thClass: 'alignCenter', tdClass: 'alignCenter', thStyle: { width: '19%' } },
        { key: 'status', label: 'Status', sortable: false, thClass: 'alignCenter', tdClass: 'alignCenter', thStyle: { width: '15%' } },
      ],

    }
  },

  methods: {
    approveAllModal() {
      this.selectedApprove = [];
      this.selectAllApprove = false;

      this.showDismissibleAlert = false;
      this.$refs['approveAll'].show();
    },

    showAlert() {
      Swal.fire({
        position: 'center',
        icon: 'error',
        text: this.error_message,
        showConfirmButton: false,
        timer: 3000
      })
    },

    getAllApprove(e) {
      this.selectedApprove = [];

      if (e) {
        var role = this.$store.getters.currentUser.role;
        var selectedApprove = [];
        this.items.forEach(function (item) {
          selectedApprove.push(item._id);
        })
        this.selectedApprove = selectedApprove;
      }
    },

    getAll(e) {
      this.selected = [];
      if (e) {
        var role = this.$store.getters.currentUser.role;
        var selected = [];
        this.items.forEach(function (item) {
          // if((item.level_user_status != null) && (item.level_user_status.role == role)){
          selected.push(item._id);
          // }
        })
        this.selected = selected;
      }
    },

    approveAll(sign) {

      // this.supervisorSign = {
      //   image: this.tempSign.image,
      //   name: 'signature.png',
      //   type: 'supervisor',
      //   default: 'yes'
      // }


      // const idsArray = this.dataListApprove.map(item => item._id);

      // console.log(this.selectedApprove)

      // return 0

      return this.$store.dispatch(POST_API, {
        data: {
          crew_signature: sign,
          id: /* this.id */this.selectedApprove,
          role: this.$store.getters.currentUser.role,
          approve_signature_time: this.approve_signature_time,
          approve_remark: this.approve_remark,
        },
        api: '/api/approve-all-inventory-order-report'
      })
      .then(() => {
        if (this.$store.getters.containsErrors) {
          this.error_message = this.$store.getters.getErrors;
          this.showDismissibleAlert = true;
        } else {
          this.showDismissibleAlert = false;

          this.successAlert().then((result) => {
            // this.filterTable();
            // this.$refs.refUserListTable.refresh();
            Bus.$emit('counter_update');
            // this.$refs['signatureModel'].hide();
            // this.tab_status = 'completed';

            // this.$refs['approveAll'].hide();
            // this.filterTable();
          });

        }
      });
    },

    multipleDelete() {
      if (this.selected.length > 0) {
        this.decisionAlert('Are you sure you want to delete selected reports?')
          .then(result => {
            if (result.value) {
              return this.$store.dispatch(POST_API, {
                data: {
                  id: this.selected,
                },
                api: "/api/delete-multiple-awcd-reports",
              })
                .then(() => {
                  if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                  } else {
                    this.successAlert();

                    this.selected = [];
                    this.selectAll = false;
                    this.selectAllApprove = false;
                    this.selectedApprove = [];
                    Bus.$emit('counter_update');
                    this.filterTable();
                  }
                });
            }
          })
          .catch(err => { })
      } else {
        this.customAlert('Please select reports.', '', 'warning')
      }
    },

    dataList() {
      this.selectAll = false;
      this.selected = [];
      this.selectAllApprove = false;
      this.selectedApprove = [];
      this.dataListApprove = [];
      this.checkMonthlyReportCount();
      return this.$store.dispatch(POST_API, {
        data: {
          page: this.currentPage,
          keyword: this.searchQuery,
          rowsPerPage: this.perPage,
          sortBy: this.sortBy,
          sortDirection: this.isSortDirDesc,
          role: this.$store.getters.currentUser.role,
          om_sites: this.$store.getters.currentUser.om_sites,
          site: this.siteData != null ? this.siteData._id : null,
          start: moment(this.start).format('YYYY-MM-DD'),
          end: moment(this.end).format('YYYY-MM-DD'),
          tab_status: this.tab_status
        },
        api: '/api/inventory-order-reports'
      })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
          } else {
            this.items = this.$store.getters.getResults.data.docs;

            for(var i = 0; i < this.items.length; i++){
              if(this.items[i].reject_to == null && this.items[i].level_user_status!== null && this.items[i].level_user_status.role == this.$store.getters.currentUser.role){
                this.dataListApprove.push(this.items[i])
              }
            }

            this.totalRecords = this.$store.getters.getResults.data.total;
            this.perPage = this.$store.getters.getResults.data.limit;
            this.from = this.$store.getters.getResults.data.from
            this.to = this.$store.getters.getResults.data.to
            this.$emit('updateCount', this.$store.getters.getResults.data.pending)
            return this.items;
          }
        });
    },

    filterTable() {
      this.$refs.refUserListTable.refresh();
    },

    variantColor(status) {
      if (status == 'active') {
        return 'success';
      } else {
        return 'primary';
      }
    },

    download(pdf) {
      window.open(pdf + '?' + new Date(), '_blank');
    },

    allSites() {
      return this.$store.dispatch(POST_API, {
        data: {
          role: this.$store.getters.currentUser.role,
          om_sites: this.$store.getters.currentUser.om_sites,
        },
        api: '/api/all-sites'
      })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
          } else {
            this.sites = this.$store.getters.getResults.data;
            // if (this.sites.length == 1 ) {
            //     this.siteData = this.sites[0]._id;
            //     this.filterTable();
            //     this.updateDateFormat();
            // }
            var obj = {
              _id: 'all-site',
              site_name: 'All Project Sites',
              pdf_date_format: 'DD MMM YYYY',
              pdf_time_format: 'HH:mm'
            }
            this.sites.unshift(obj);

            this.siteData = this.defaultSite(this.sites)._id;
            this.filterTable();
            this.updateDateFormat();

            return this.sites;
          }
        });
    },

    // calendar functions
    updateStart() {
      this.end = moment(this.start).tz('Asia/Singapore').toDate();
      this.filterTable();
    },
    disabledDates() {
      if (this.start) {
        return moment(this.start).format('YYYY-MM-DD');
      } else {
        return moment().format('YYYY-MM-DD');
      }
    },

    generatePurchaseOrder(id) {
      return this.$store.dispatch(POST_API, {
        data: {
          data: id
        },
        api: "/api/inventory-generate-purchase-order",
      })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.successAlert();
            this.filterTable();
          }
        });
    },
    deleteItem(id) {
      var msg = 'Are you sure want to delete this record?';
      var message = 'Inventory Order Report Deleted Successfully.';

      Swal.fire({
        title: 'Please Confirm',
        text: msg,
        icon: 'warning',
        position: 'top-center',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
        .then(result => {
          if (result.value) {
            return this.$store.dispatch(POST_API, {
              data: {
                id: id,
                status: 'deleted'
              },
              api: "/api/change-status-inventory-order-report-data",
            })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {

                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1500
                  })
                  Bus.$emit('counter_update');
                  this.$refs.refUserListTable.refresh();
                }
              });
          }
        })
        .catch(err => {
        })
    },
    openSignature(id, date, special_requisition) {
      if (special_requisition && special_requisition.length > 0) {
        this.customAlert('', 'There is Special Requisition. Please Acknowledge.', 'info')
      } else {
        if (this.having_default == true) {
          this.show_default = true;
        } else {
          this.show_default = false;
        }
        this.checked_date = date;
        this.approve_signature_time = moment(new Date()).tz('Asia/Singapore').format('DD MMM YYYY HH:mm');
        this.approve_remark = '';
        this.id = id;
        this.$refs['signatureModel'].show();
      }
    },
    useDefault() {

      return this.$store.dispatch(POST_API, {
        data: {
          id: this.$store.getters.currentUser._id
        },
        api: '/api/get-default-signature'
      })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showDismissibleAlert = true;
            window.scrollTo(0, 0);
          } else {
            this.showDismissibleAlert = false;
            var data = this.$store.getters.getResults.data;

            if (data == null || data.default_signature == null) {

              this.having_default = false;

              this.tempSign = {
                image: null,
                name: '',
              }

            } else {

              this.tempSign = {
                image: data.default_signature,
                name: 'signature.png',
              }

              this.having_default = true;
            }
          }
        });
    },
    saveDefault() {
      this.supervisorSign = {
        image: this.tempSign.image,
        name: 'signature.png',
        type: 'supervisor',
        default: 'yes'
      }
      this.approveReport(this.supervisorSign);
      this.$refs['signatureModel'].hide();
    },
    saveDefaultAll() {
      this.supervisorSign = {
        image: this.tempSign.image,
        name: 'signature.png',
        type: 'supervisor',
        default: 'yes'
      }
      this.approveAll(this.supervisorSign).then(()=>{
        // this.tab_status = 'completed';
        this.$refs['signatureModel'].hide();
        this.$refs.refUserListTable.refresh();
      })
    },
    hideSignature() {
      this.openedSignModel = null;
    },
    clearSignature() {
      this.$refs.signaturePad.clearSignature();
    },

    saveSignature() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Signature is required',
          showConfirmButton: false,
          timer: 1500
        })
      } else {
        this.supervisorSign.image = data;
        this.supervisorSign.name = 'signature.png';
        this.supervisorSign.type = 'supervisor';
        this.supervisorSign.default = 'no';

        this.approveReport(this.supervisorSign);
        this.$refs['signatureModel'].hide();
      }
    },
    saveSignatureAll() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Signature is required',
          showConfirmButton: false,
          timer: 1500
        })
      } else {
        this.supervisorSign.image = data;
        this.supervisorSign.name = 'signature.png';
        this.supervisorSign.type = 'supervisor';
        this.supervisorSign.default = 'no';

        this.approveAll(this.supervisorSign).then(()=>{
          // this.tab_status = 'completed';
          this.$refs['signatureModel'].hide();
          this.$refs.refUserListTable.refresh();
        });
      }
    },
    approveReport(sign) {

      return this.$store.dispatch(POST_API, {
        data: {
          crew_signature: sign,
          id: this.id,
          role: this.$store.getters.currentUser.role,
          approve_signature_time: this.approve_signature_time,
          approve_remark: this.approve_remark,
        },
        api: '/api/approve-inventory-order-report'
      })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            /*this.showDismissibleAlert = true;
            window.scrollTo(0,0);*/
            this.errorAlertCF();
            //this.$refs.refUserListTable.refresh();
          } else {
            this.showDismissibleAlert = false;
            var data = this.$store.getters.getResults.data;
            this.successAlert();
            this.$refs.refUserListTable.refresh();

            Bus.$emit('counter_update');
            window.open(data, '_blank');
          }
        });
    },
    breadCrumb() {
      var item = [{
        to: { name: 'client-dashboard' },
        text: 'Dashboard',
      }, {
        to: null,
        text: 'Reports',
      }, {
        to: { name: 'custom-forms-dashboard' },
        text: 'Form Dashboard',
      }, {
        to: { name: 'custom-forms-reports' },
        text: 'Custom Forms'
      }, {
        to: null,
        text: 'Admin Wipes Checklist (Day)',
        active: true
      }];
      return this.filterReportBreadCrum(item);
    },
    updateDateFormat() {

      if (this.siteData != '') {
        this.sites.forEach(item => {
          if (item._id == this.siteData) {
            this.date_format = item.pdf_date_format;
            this.time_format = item.pdf_time_format;
          }
        })

      } else {
        this.date_format = 'DD MMM YYYY';
        this.time_format = 'HH:mm';
      }
    },
    disabledDate(date) {
      return date < moment(new Date(this.checked_date)).subtract(1, 'days').tz('Asia/Singapore');
    },
    openRejectBox(item) {
      this.showDismissibleAlert = false;
      this.id = item._id;
      this.comment = item.reject_comment;
      this.$refs['rejectbox'].show();
    },
    markReject(e) {
      e.preventDefault();

      return this.$store.dispatch(POST_API, {
        data: {
          comment: this.comment,
          id: this.id,
          role: this.$store.getters.currentUser.role
        },
        api: '/api/reject-inventory-order-report'
      })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showDismissibleAlert = true;
          } else {
            this.showDismissibleAlert = false;
            var message = this.$store.getters.getResults.message;
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: message,
              showConfirmButton: false,
              timer: 1500
            }).then((result) => {
              this.filterTable();
              Bus.$emit('counter_update');
              this.$refs['rejectbox'].hide();
            });


          }
        });
    },
    allowReject(item) {
      var role = this.$store.getters.currentUser.role;

      if (item.reject_to == role && ((item.level_one_user_role != role) && (item.level_two_user_role != role) && (item.level_three_user_role != role))) {
        return false;
      } else if (item.reject_by == role) {
        return false;
      } else if ((item.level_user_status != null) && (item.level_user_status.role == '')) {
        return false;
      } else {
        return true;
      }
    },


    zipDownload() {
      var organization = this.$store.getters.currentUser.organization;
      var id = this.selected && this.selected.length > 0 ? this.selected.toString() : [];
      var start = moment(this.start).format('YYYY-MM-DD');
      var end = moment(this.end).format('YYYY-MM-DD');

      var params = "organization=" + organization + "&id=" + id + "&start=" + start + "&end=" + end;
      var baseurl = "";

      baseurl = process.env.VUE_APP_SERVER_URL + '/api/inventory-order-zip-download?' + params;
      this.selectAll = false,
      this.selected = []
      
      this.selectAllApprove = false;
      this.selectedApprove = [];

      window.open(baseurl, '_blank');
    },
    getSupplierCategoriesText(orderData) {
      var text = [];

      for (var i = 0; i < orderData.length; i++) {
        var arr = [];

        arr.push(orderData[i].supplier_name);

        // if(orderData[i].category_name != 'General') {
        //   arr.push(orderData[i].category_name)
        // }

        // if (orderData[i].sub_category_name != 'General') {
        //   arr.push(orderData[i].sub_category_name)
        // }

        //text.push(arr.join(''));
        text.push(arr);
      }

      return text.join(', ');

    },

    checkMonthlyReportCount() {
      return this.$store.dispatch(POST_API, {
        data: {
          role: this.$store.getters.currentUser.role,
          // organization: this.siteData ? this.siteData.organization : ''
          site: this.siteData != null ? this.siteData._id : null,
        },
        api: '/api/check-inventory-order-monthly-count'
      })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showDismissibleAlert = true;
          } else {
            if (this.$store.getters.getResults.data > 0 && this.$store.getters.currentUser.role == 'supervisor') {
              this.checkOrderCount = false;
            } else {
              this.checkOrderCount = true;
            }
            Bus.$emit('counter_update_sites', this.siteData != null ? this.siteData._id : null);
          }
        });
    },
    sendEmail() {
      return this.$store.dispatch(POST_API, {
        data: {
          role: this.$store.getters.currentUser.role,
          om_sites: this.$store.getters.currentUser.om_sites,
          site: this.siteData != null ? this.siteData._id : null,
          start: moment(this.start).format('YYYY-MM-DD'),
          end: moment(this.end).format('YYYY-MM-DD'),

          // role : this.$store.getters.currentUser.role,
          // om_sites:this.$store.getters.currentUser.om_sites,
          // site:this.$store.getters.currentUser.site ?this.$store.getters.currentUser.site._id:null
        },
        api: '/api/send-pending-order-email'
      })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.successAlert();
          }
        });
    }

  },
  mounted() {
    this.useDefault();
    this.checkMonthlyReportCount();
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
